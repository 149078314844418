import React from 'react'
import loadable from '@loadable/component'

const EarningInsightStories = loadable(() => import('./EarningInsightStories'))

export const LazyEarningInsightStories = (props) => {
    return (
      <EarningInsightStories {...props} />
    )
}

export default LazyEarningInsightStories