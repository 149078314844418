import React from 'react'
import { globalHistory } from '@reach/router'
import tw, { css, theme } from 'twin.macro'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

  const breakpoints = [640, 768, 1024]

  const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  const maxMq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

  const Notify = class extends React.Component {

    constructor(props) {
      super(props)
      this.state = { isValidated: false, fname: '', email: '', cta: '', feedbackMsg: null, title: props.title ? `Project update: ${props.title}` : 'General Projects Update'}
    }

    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
      e.preventDefault()
      const form = e.target
      fetch(`${globalHistory.location.pathname}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(response =>
          this.setState({
            feedbackMsg: 'Form submitted successfully!',
          }),
        )
        .catch(error => alert(error))
    }


    render() {
      const { fname, email, feedbackMsg, title } = this.state
      return (
        <div
          className="form"
          tw="md:border border-t p-0 pt-6 md:p-8 md:rounded md:shadow-sm font-serif mt-0"
          css={{
            gridColumn: '1/-1',
            width: 'calc(100% - 48px)',
            margin: '0 auto',
            borderColor: `${theme`borderColor.default`}`,
            [mq[1]]: {
              backgroundColor: `${theme`backgroundColor.secondary`}`,
            },
            [mq[2]]: {
              width: '800px',
            },
          }}
        >
          <p tw="text-2xl" css={{ margin: '0 !important' }}>
            “{this.props.title}” is in draft mode
          </p>
          {feedbackMsg ? (
            <p css={{ marginTop: '0.75rem !important', marginBottom: '0.75rem !important' }}>
              Thank you for your interest! I'll keep you posted{' '}
              <span role="img" aria-label="emoji">
                🙏🏻
              </span>
            </p>
          ) : (
            <p
              css={{
                marginTop: '0.75rem !important',
                marginBottom: '0 !important',
              }}
            >
              I can send you an update once it’s up and ready.
            </p>
          )}

          <form
            name={title}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
            tw="flex flex-col sm:flex-row space-y-4 sm:space-y-4 sm:space-x-4 items-end font-sans font-normal text-base mt-4 sm:mt-0"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value={title} />
            <div hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            {feedbackMsg ? (
              ''
            ) : (
              <>
                <div tw="w-full sm:w-1/3" css={{ margin: '0 !important' }}>
                  <label
                    htmlFor={'fname'}
                    tw="inline-flex mb-2 text-xs sm:text-sm text-softer pl-4"
                  >
                    Name
                  </label>
                  <input
                    required
                    tw="block w-full text-base rounded-md pl-4 focus:ring-0 bg-primary text-primary border-none"
                    css={{
                      boxShadow: '0 0 0 1px var(--color-border)',
                      ':focus': {
                        boxShadow: '0 0 0 1px var(--color-primary)',
                      },
                    }}
                    type={'text'}
                    name={'fname'}
                    value={fname}
                    onChange={this.handleChange}
                    id={'fname'}
                    placeholder={'Your first name'}
                  />
                </div>
                <div tw="w-full sm:w-1/3">
                  <label
                    htmlFor={'email'}
                    tw="inline-flex mb-2 text-xs sm:text-sm text-softer pl-4"
                  >
                    Email
                  </label>
                  <input
                    required
                    tw="block w-full text-base rounded-md pl-4 focus:ring-0 bg-primary text-primary border-none"
                    css={{
                      boxShadow: '0 0 0 1px var(--color-border)',
                      ':focus': {
                        boxShadow: '0 0 0 1px var(--color-accent)',
                      },
                    }}
                    type={'email'}
                    name={'email'}
                    value={email}
                    onChange={this.handleChange}
                    id={'email'}
                    placeholder={'Your email address'}
                  />
                </div>
                <button
                  tw="w-full sm:w-1/3 rounded-full p-2 font-medium"
                  css={{
                    backgroundColor: `${theme`colors.accent`}`,
                    color: `var(--text-cta)`,
                    boxShadow: '0 0 0 1px var(--color-primary), 0 1px 1px 1px var(--color-accent)',
                    '&:hover, &:focus': {
                      backgroundColor: `${theme`colors.primary`}`,
                    },
                  }}
                  type="submit"
                >
                  Get update
                </button>
              </>
            )}
          </form>
        </div>
      )
    }
  }


export default Notify
