import LazyEarningInsightStories from "../../../../src/content/projects/earning-insight/LazyEarningInsightStories";
import LazyEarningInsight from "../../../../src/content/projects/earning-insight/LazyEarningInsight";
import LazyChoice from "../../../../src/content/projects/earning-insight/LazyChoice";
import LazyPressured from "../../../../src/content/projects/earning-insight/LazyPressured";
import BehindTheScene from "../../../../src/content/projects/earning-insight/BehindTheScene";
import { Hidden } from "../../../../src/components/hidden";
import * as React from 'react';
export default {
  LazyEarningInsightStories,
  LazyEarningInsight,
  LazyChoice,
  LazyPressured,
  BehindTheScene,
  Hidden,
  React
};