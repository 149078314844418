import React from 'react'
import tw, { css, theme } from 'twin.macro'

export const Books = () => {
  return (
    <div className="books">
      <div tw="mt-8 md:mt-16 w-full flex flex-row overflow-hidden space-x-4 md:space-x-16">
        <div
          tw=" m-0 px-6 py-4 md:p-8 md:pb-4 rounded w-40 h-48 md:h-72 md:w-56 border shadow-inner flex flex-wrap content-between"
          css={{
            backgroundColor: `${theme`colors.accent`}`,
            fontWeight: '400 !important',
            position: 'relative',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: 0,
              left: 10,
              bottom: 0,
              width: 4,
              background: 'rgba(0,0,0,0.09)',
            },
          }}
        >
          <h4
            css={{
              color: 'var(--text-cta) !important',
              margin: '0 !important',
            }}
          >
            Nudging Responsibly
          </h4>
          <div>
            <small tw="font-sans text-cta opacity-30">2019</small>
          </div>
        </div>
        <div
          tw="m-0 px-6 py-4 md:p-8 md:pb-4 rounded w-40 h-48 md:h-72 md:w-56 border shadow-inner flex flex-wrap content-between"
          css={{
            backgroundColor: `${theme`colors.accent`}`,
            fontWeight: '400 !important',
            position: 'relative',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: 0,
              left: 12,
              bottom: 0,
              width: 4,
              background: 'rgba(0,0,0,0.09)',
            },
          }}
        >
          <h4
            css={{
              color: 'var(--text-cta) !important',
              margin: '0 !important',
            }}
          >
            Ethical Framework
          </h4>
          <div>
            <small tw="font-sans text-cta opacity-30">2019</small>
          </div>
        </div>
        <div
          tw=" m-0 px-6 py-4 md:p-8 md:pb-4 rounded w-40 h-48 md:h-72 md:w-56 border shadow-inner flex flex-wrap content-between"
          css={{
            backgroundColor: `${theme`colors.accent`}`,
            fontWeight: '400 !important',
            position: 'relative',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: 0,
              left: 10,
              bottom: 0,
              width: 4,
              background: 'rgba(0,0,0,0.09)',
            },
          }}
        >
          <h4
            css={{
              color: 'var(--text-cta) !important',
              margin: '0 !important',
            }}
          >
            Gamification Playbook
          </h4>
          <div>
            <small tw="font-sans text-cta opacity-30">2020</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Books
