import Title from "../../../../src/content/work/Title";
import LazyGallery from "../../../../src/content/work/LazyGallery";
import Podcast from "../../../../src/content/work/Podcast";
import BSU from "../../../../src/content/work/BSU";
import Books from "../../../../src/content/work/Books";
import * as React from 'react';
export default {
  Title,
  LazyGallery,
  Podcast,
  BSU,
  Books,
  React
};