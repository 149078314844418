import React from 'react'
import tw, { css, theme } from 'twin.macro'
import Grab from '../../assets/grab.svg'


export const Title = () => {
  return (
    <div tw="flex flex-row items-center justify-start">
      <h2 css={{ 
        marginBottom: '0 !important', 
        marginTop: '0.5rem !important',
        '@media (max-width: 375px)': {
          fontSize: '1.4rem !important',
        },
        '@media (max-width: 320px)': {
          fontSize: '1.1rem !important',
        },
        }}>
        Lead Product Designer at
      </h2>
      <Grab
        tw="ml-1.5 sm:ml-2"
        css={{
          height: 56,
          '@media (max-width: 375px)': {
            height: 32,
            width: 56,
          },
          '@media (min-width: 375px) and (max-width: 1024px)': {
            height: 40,
            width: 64,
          },
          '@media (min-width: 1024px) and (max-width: 1280px)': {
            height: 48,
            width: 76,
          },
        }}
      />
    </div>
  )
}

export default Title
