import React from 'react'
import tw, { css, theme } from 'twin.macro'
import btut from './btut.mp4'
import btutwebm from './btut.webm'

export const BehindTheUT = () => {
  return (
      <video
        controls
        tw="mt-0 m-auto shadow-md rounded"
        css={{
          maxHeight: 480,
        }}
      >
        <source src={btut} type="video/mp4"/>
        <source src={btutwebm} type="video/webm"/>
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
  )
}

export default BehindTheUT
