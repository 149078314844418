import React from 'react'
import loadable from '@loadable/component'

const EarningInsight = loadable(() => import('./EarningInsight'))

export const LazyEarningInsight = (props) => {
    return (
      <EarningInsight {...props} />
    )
}

export default LazyEarningInsight