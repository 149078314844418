import React from 'react'
import tw, { css, theme } from 'twin.macro'
import bts from './bts.mp4'
import btswebm from './bts.webm'
import btut from './btut.mp4'
import btutwebm from './btut.webm'

export const FareAlertBehindTheScene = () => {
  return (
    <div
      className="fare-alert-exploration"
      css={{ backgroundColor: `${theme`borderColor.accent`}` }}
    >
      <div tw="flex flex-col sm:flex-row sm:space-x-8 items-center justify-center pt-4">
        <video
          controls
          tw="shadow-md rounded"
          css={{
            maxHeight: 480,
          }}
        >
          <source src={bts} type="video/mp4" />
          <source src={btswebm} type="video/webm" />
          <p>Your browser doesn't support HTML5 video.</p>
        </video>
        <video
          controls
          tw="shadow-md rounded"
          css={{
            maxHeight: 480,
          }}
        >
          <source src={btut} type="video/mp4" />
          <source src={btutwebm} type="video/webm" />
          <p>Your browser doesn't support HTML5 video.</p>
        </video>
      </div>
      <div
        tw="font-sans"
        css={{
          overflow: 'hidden',
          textAlign: 'center',
          width: '100% !important',
          fontWeight: 400,
          color: 'var(--text-primary) !important',
          fontSize: '0.75rem !important',
          padding: '0.5rem !important',
          margin: '0 !important',
        }}
      >
        Behind the scenes footages of Concept Testing & Usability Testing prototypes made in Framer
        Classic.
      </div>
    </div>
  )
}

export default FareAlertBehindTheScene
