import React from 'react'
import tw, { css, theme } from 'twin.macro'
import earninginsight from './earning-insight.mp4'
import earninginsightwebm from './earning-insight.webm'

export const BehindTheScene = () => {
  return (
    <div
      className="fare-alert-exploration"
      css={{ backgroundColor: `${theme`borderColor.accent`}` }}
      tw="flex flex-col gap-y-4"
    >
      <video
        controls
        width="400"
        tw="m-auto shadow-md rounded"
        css={{
          maxWidth: 'min(400px, calc(100% - 40px))',
        }}
      >
        <source src={earninginsight} type="video/mp4" />
        <source src={earninginsightwebm} type="video/webm" />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      <div
        tw="font-sans"
        css={{
          overflow: 'hidden',
          textAlign: 'center',
          width: '100% !important',
          fontWeight: 400,
          color: 'var(--text-primary) !important',
          fontSize: '0.75rem !important',
          padding: '0.5rem !important',
          margin: '0 !important',
        }}
      >
        Behind the scene footage of Earning Insight feature on our Android test device.
      </div>
    </div>
  )
}

export default BehindTheScene
