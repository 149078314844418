import React from 'react'
import tw, { css, theme } from 'twin.macro'
import ut from './ut.mp4'
import utwebm from './ut.webm'

export const UsabilityTest = () => {
  return (
    <div
      className="fare-alert-exploration"
      css={{ backgroundColor: `${theme`borderColor.accent`}` }}
      tw="flex flex-col"
    >
      <video
        controls
        width="400"
        tw="m-auto shadow-md rounded"
        css={{
          maxWidth: 'min(400px, calc(100% - 40px))',
        }}
      >
        <source src={ut} type="video/mp4"/>
        <source src={utwebm} type="video/webm"/>
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      <div
        tw="font-sans"
        css={{
          overflow: 'hidden',
          textAlign: 'center',
          width: '100% !important',
          fontWeight: 400,
          color: 'var(--text-primary) !important',
          fontSize: '0.75rem !important',
          padding: '0.5rem !important',
          margin: '0 !important',
        }}
      >
        I messed up my Usability Testing Framer file but luckily it's almost identical to the feature that is now live.
      </div>
    </div>
  )
}

export default UsabilityTest
