import React from 'react'
import loadable from '@loadable/component'

const Pressured = loadable(() => import('./Pressured'))

export const LazyPressured = (props) => {
    return (
      <Pressured {...props} />
    )
}

export default LazyPressured