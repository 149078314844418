import React from 'react'
import loadable from '@loadable/component'

const Gallery = loadable(() => import('./Gallery'))

export const LazyGallery = (props) => {
  return (
    <>
      <Gallery {...props} />
    </>
  )
}

export default LazyGallery
