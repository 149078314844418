import React from 'react'
import tw, { css, theme } from 'twin.macro'
import podcast from './podcast.mp4'
import podcastwebm from './podcast.webm'

export const Podcast = () => {
  return (
    <div className="podcast">
      <div tw="w-full flex flex-col-reverse md:flex-row-reverse items-center">
        <div tw="md:w-1/2 flex justify-end">
          <video 
            controls 
            width="400" 
            tw="rounded shadow-sm">
            <source src={podcast} type="video/mp4" />
            <source src={podcastwebm} type="video/webm" />
            <p>Your browser doesn‘t support HTML5 video.</p>
          </video>
        </div>
        <div tw="md:w-1/2">
          <h3>Grab Design Podcast</h3>
          <p>
            Together with a fellow designer,{' '}
            <a href="https://www.linkedin.com/in/tim-lange/" target="_blank">Tim Lange</a>, I started and co-hosted
            an internal design podcast back in 2019. We had 11 episodes in which we talked to
            Grabbers from different roles and backgrounds. We sat down and have a casual and candid
            chat about work-related topics, personal interests, and observations.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Podcast
