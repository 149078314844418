import React from 'react'
import tw, { css, theme } from 'twin.macro'

export const BSU = () => {
  return (
    <div className="behavioral-science">
      <div tw="mt-2 md:mt-4 w-full flex flex-col">
        <div tw="text-left">
          <h3>Experimenting with Behavioral Science</h3>
          <p>
            While I was in the Marketplace team I had the privilege of working with an exceptional
            Behavioral Scientist,{' '}
            <a href="https://www.theonionbrain.com/about-me" target="_blank">Preeti Kotamarthi</a>. We formed a
            strong partnership and together we have co-led and started a handful of cross-discipline
            initiatives like Ethical Framework (2019), Nudging Responsibly (2019), and Gamification
            Playbook (2020). Having the opportunity to learn and apply these concepts through
            projects and experiments has really opened up a fresh perspective and approach for my
            design process.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BSU
