import React from 'react'
import loadable from '@loadable/component'

const FareAlertStories = loadable(() => import('./FareAlertStories'))

export const LazyFareAlertStories = (props) => {
    return (
      <FareAlertStories {...props} />
    )
}

export default LazyFareAlertStories