import React from 'react'
import loadable from '@loadable/component'

const Choice = loadable(() => import('./Choice'))

export const LazyChoice = (props) => {
    return (
      <Choice {...props} />
    )
}

export default LazyChoice